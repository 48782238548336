import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { load as loadYaml } from 'js-yaml';

import Backend from 'i18next-http-backend';

const backend = {
	loadPath: '/assets/locales/{{lng}}.yml',
	parse: loadYaml,
};

function getDefaultLang(): string {
	const params = (new URL(window.location.href)).searchParams;
	return params.get('lang') || 'et';
}

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		lng: getDefaultLang(),
		backend,
		debug: true,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		react: {
			wait: true,
			useSuspense: false,
		},
		fallbackLng: ['et', 'en'],
		initImmediate: false,
	})
	.catch(console.error);

export default i18n;
