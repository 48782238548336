import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { Lyrics } from './Lyrics/Lyrics';
import { findCurrentCueIndex } from '../utils/findCueNumber';
import { PlayerControls } from './PlayerControls';

const PLAYER_MEDIA_TYPE = 'audio/mp3';

const Video = styled.video`
	display: none;
	object-fit: initial;
`;

interface Controls {
	visible: boolean;
}

const Controls = styled(PlayerControls)<Controls>`
	display: ${(p) => (p.visible ? 'block' : 'none')};
	margin: 24px 0;
	width: 100%;
`;

interface Player {
	lyrics: string;
	audio: string;
	subtitles: string;
	nextTrackId?: string;
	prevTrackId?: string;
}

export const Player: FC<Player> = ({ audio, subtitles, lyrics, nextTrackId, prevTrackId }) => {
	const l = useMemo(() => lyrics, [lyrics]);
	const [sourceURL, setSourceURL] = useState<string | null>(null);
	const [currentRow, setCurrentRow] = useState<number | null>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const textTrackRef = useRef<HTMLTrackElement>(null);

	const [customControlsVisible] = useState<boolean>(true);
	const [progressValue, setProgressValue] = useState<number>(0);

	useEffect(() => {
		if (!subtitles) return;
		fetch(subtitles)
			.then(async (response) => await response.text())
			.then(function (responseText) {
				const blob = new Blob([responseText], { type: 'text/vtt' });
				const objectURL = URL.createObjectURL(blob);
				setSourceURL(objectURL);
			});
	}, [subtitles, audio]);

	function cueChange() {
		const currentTrack = textTrackRef?.current?.track;
		if (!currentTrack) return;

		const currentCueIndex = findCurrentCueIndex(currentTrack);
		if (currentCueIndex !== null) setCurrentRow(currentCueIndex);
	}

	useEffect(() => {
		let track = textTrackRef?.current?.track;
		if (track) track.mode = 'showing';

		setCurrentRow(0);
		setProgressValue(0);
		track?.addEventListener('cuechange', cueChange);

		return () => {
			track?.removeEventListener('cuechange', cueChange);
		};
	}, [sourceURL]);

	useEffect(() => {
		const video = videoRef.current;
		const supportsMedia = !!video?.canPlayType(PLAYER_MEDIA_TYPE);

		if (!video || !supportsMedia) return;

		video.controls = false; // Workaround for an iPad?
		// setCustomControlsVisible(true);

		video.currentTime && setProgressValue(video.currentTime);
	}, [videoRef]);

	function handleTimeUpdate() {
		const video = videoRef.current;
		if (!video) return;
		setProgressValue(video.currentTime / video.duration);
	}

	return (
		<>
			<Video
				key={audio}
				ref={videoRef}
				height={40}
				width={800}
				controls
				disablePictureInPicture
				playsInline={true}
				onTimeUpdate={handleTimeUpdate}
			>
				<source src={audio} type={PLAYER_MEDIA_TYPE} />
				{sourceURL && (
					<track
						key={sourceURL}
						ref={textTrackRef}
						src={sourceURL}
						kind="subtitles"
						srcLang="et"
						label="Estonian"
						default
					/>
				)}
				Your browser does not support the video tag.
			</Video>
			{videoRef.current && (
				<Controls
					visible={customControlsVisible}
					video={videoRef.current}
					progressValue={progressValue}
					nextTrackId={nextTrackId}
					prevTrackId={prevTrackId}
				/>
			)}
			<Lyrics lyrics={l} activeRowIndex={currentRow} />
		</>
	);
};
