import styled from 'styled-components';
import { ReactComponent as Border } from '../assets/icons/frame.svg';
import React, { FC } from 'react';

interface AvatarContent {
	size: string;
}

const Frame = styled(Border)`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: var(--avatar-width);
`;

const AvatarContainer = styled.div<AvatarContent>`
	position: relative;
	width: auto;
	display: flex;
	align-items: center;
`;

export const AvatarContent = styled.img<AvatarContent>`
	width: 3rem;
	clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%, 50% 0);
	margin-right: 16px;

	${(p) =>
		p.size === 'large' &&
		`
    width: var(--avatar-content-width);
	margin-left: var(--avatar-side-width);
	margin-right: var(--avatar-side-width);
    `}
`;

interface Avatar {
	src?: string;
	size: string;
	className?: string;
}

export const Avatar: FC<Avatar> = ({ src, size, className }) => {
	return (
		<AvatarContainer aria-hidden={true} className={className} size={size}>
			{size === 'large' && <Frame />}

			<AvatarContent alt="" src={src} size={size} />
		</AvatarContainer>
	);
};
