import styled from 'styled-components';
import React, { FC } from 'react';
import { CommentItem, useLyricsContext } from '../../contexts/LyricsContext';

interface Comment {
	href: string;
	title?: string;
	content: string;
}

interface CommentKey {
	active: boolean;
}

const CommentKey = styled.button<CommentKey>`
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 5px;
	color: #00a891;
	cursor: pointer;
	background: transparent;
	padding: 0;
	border: none;

	${(p) => p.active && 'color: #00d2aa;'}
`;

export const Comment: FC<Comment> = ({ children, href, title }) => {
	const { currentComment, setCurrentComment } = useLyricsContext();

	const comment: CommentItem = { key: children?.toString(), explanation: title };

	return (
		<CommentKey onClick={() => setCurrentComment(comment)} active={currentComment?.explanation === title}>
			{children}
		</CommentKey>
	);
};
