import styled from 'styled-components';
import { screenSize } from '../styles/ScreenSize';

export const Layout = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
	height: calc(100vh - 5vh - 2.5vh);
	padding: 16px;
	max-width: 1000px;
	margin: 5vh auto 2.5vh auto;

	${screenSize.sm} {
		height: 100vh;
		margin: 0;
	}
`;
