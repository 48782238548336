import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ViewParams } from '../types/types';

export interface CommentItem {
	key?: string;
	explanation?: string;
}

interface LyricsContext {
	currentComment?: CommentItem | null;
	setCurrentComment(comment?: CommentItem | null): void;
}

export const LyricsContext = createContext<LyricsContext>({} as LyricsContext);

export const LyricsContextProvider: FC = ({ children }) => {
	const { trackId } = useParams<ViewParams>();

	const [currentComment, setCurrentComment] = useState<CommentItem | null>();

	useEffect(() => {
		setCurrentComment(null);
	}, [trackId]);

	return <LyricsContext.Provider children={children} value={{ currentComment, setCurrentComment }} />;
};

export const useLyricsContext = () => useContext(LyricsContext);
