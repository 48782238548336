import { globalStyles } from '@erm-tasku/styles';
import { createGlobalStyle } from 'styled-components';
import { screenSize } from './ScreenSize';

export const GlobalStyles = createGlobalStyle`
  ${globalStyles}

  :root {
    --background: #001C29;
    --active-highlight: var(--transparent-blue);

    --primary-color: var(--white);
    --secondary-color: var(--blue);
    --active-lyric-color: var(--white);

    --body-text: var(--primary-text-size);
    --tag-text: var(--secondary-text-size);

    --avatar-width: 131px;
    --avatar-height: 102px;
    --avatar-content-width: 87px;
    --avatar-side-width: 21.65px;

    ${screenSize.sm} {
    --avatar-width: 94px;
    --avatar-height: 73px;
    --avatar-content-width: 62px;
    --avatar-side-width: calc((var(--avatar-width) - var(--avatar-content-width)) / 2);
    }
  }

  body {
    background-color: var(--background);

    color: var(--primary-color);

    font-family: var(--body-font);
    font-size: var(--body-text);
    height: 100%;
  }

  pre {
    font-family: var(--body-font);
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;
