import React from 'react';

export function getXPositionNormalized(e: React.MouseEvent) {
	const currentValue = e.nativeEvent.offsetX;
	const maxValue = e.currentTarget.getBoundingClientRect().width || 0;
	return maxValue ? currentValue / maxValue : 0;
}

export function getNormalizedValue(value: number, min: number, max: number) {
	return (value - min) / (max - min);
}
