import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getNormalizedValue, getXPositionNormalized } from './helpers';
import { SliderThumb } from './SliderThumb';
import { ReactComponent as SliderEnd } from '../../assets/icons/slider-end.svg';
import { useWindowSize } from '../../hooks/useWindowSize';

const Svg = styled.svg`
	//shape-rendering: crispEdges;
	overflow: visible;
	user-select: none;
	position: absolute;
`;

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	cursor: pointer;
`;

const End = styled(SliderEnd)`
	position: absolute;

	stroke: var(--primary-color);
	stroke-width: 2px;
`;
interface Slider {
	onChange: (normalizedValue: number) => void;
	value: number;
	max?: number;
	min?: number;
	className?: string;
}

export const Slider: React.FC<Slider> = ({ value = 0, onChange, max = 1, min = 0 }) => {
	const [mouseDown, setMouseDown] = useState<boolean>(false);
	const [localValue, setLocalValue] = useState<number>(value); // Local value makes it feel smoother as it renders faster
	const [sliderWidth, setSliderWidth] = useState<number>(0);
	const windowSize = useWindowSize();
	const sliderContainer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		sliderContainer.current && setSliderWidth(sliderContainer.current.offsetWidth);
	}, [windowSize]);

	window.onmouseup = () => {
		setMouseDown(false);
	};

	useEffect(() => setLocalValue(value), [value]);

	function handleScrub(e: React.MouseEvent) {
		e.preventDefault();
		const newPosition = getXPositionNormalized(e);
		setLocalValue(newPosition);
		onChange?.(newPosition);
	}

	// May need window mouse move event to prevent jumping when pointer moves too far from slider
	// But native event is missing client bounds rect
	function handleMove(e: React.MouseEvent) {
		if (!mouseDown) return;
		handleScrub(e);
	}

	function handleMouseDown() {
		setMouseDown(true);
	}

	const normalizedValue = getNormalizedValue(localValue, min, max);

	const height = 48;
	const sliderEndWidth = 14;
	const thumbWidth = 24;
	const lineWidth = sliderWidth - 2 * sliderEndWidth;

	//TODO: Styling and adjustments
	return (
		<Container ref={sliderContainer}>
			<End width={sliderEndWidth} height="24" style={{ left: '0' }} />
			<Svg
				width={lineWidth}
				height={height}
				onPointerDown={handleMouseDown}
				onPointerMove={handleMove}
				onClick={handleScrub}
				style={{ marginLeft: sliderEndWidth }}
			>
				<line
					x1={0}
					y1={height / 2}
					x2={lineWidth}
					y2={height / 2}
					stroke={'var(--primary-color, #fff)'}
					strokeWidth="2px"
				/>
				<line
					x1={0}
					y1={height / 2}
					x2={lineWidth * normalizedValue}
					y2={height / 2}
					stroke={'var(--secondary-color, blue)'}
					strokeWidth="2px"
				/>
			</Svg>
			<SliderThumb position={normalizedValue * (lineWidth - thumbWidth) + sliderEndWidth} />
			<End width={sliderEndWidth} height="24" style={{ right: `0` }} />
		</Container>
	);
};
