import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ScrollBox } from '@erm-tasku/components';
import '@erm-tasku/components/lib/bundle.css';

import { incrementLineNumber } from '../../utils/incrementLine';
import { LyricsContextProvider } from '../../contexts/LyricsContext';
import { Comment } from './Comment';
import { CommentOverlay } from './CommentOverlay';
import { Separator } from '../Separator';
import { screenSize } from '../../styles/ScreenSize';

const Indented = styled.span`
	margin-left: 24px;
`;

const Container = styled.div`
	display: flex;
	height: 100%;
	align-items: flex-start;

	${screenSize.sm} {
		display: flex;
		flex-direction: column;
		max-width: 100%;
	}
`;

const LyricsContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 50%;

	${screenSize.sm} {
		flex: 1 1 0;
		width: 100%;
	}
`;

const StyledScrollBox = styled(ScrollBox)`
	margin: -7px 0;
	width: 100%;
	height: 0;
	overflow-y: auto;
	flex: 1 1 0;
`;

const Indent: FC = ({ children }) => <Indented>{children}</Indented>;

interface Line {
	highlighted: boolean;
}

const Line = styled.pre<Line>`
	color: ${(p) => (p.highlighted ? 'var(--active-lyric-color)' : 'var(--secondary-color)')};

	button {
		${(p) => p.highlighted && 'color: var(--active-lyric-color)'};
	}

	margin: 0;
	white-space: pre-wrap;
	line-height: 2rem;

	:first-child {
		margin-top: 16px;
	}

	:last-child {
		margin-bottom: 16px;
	}

	pre {
		margin: 0;
	}
`;

interface Lyrics {
	lyrics?: string;
	activeRowIndex: number | null;
}

export const Lyrics: FC<Lyrics> = ({ lyrics, activeRowIndex }) => {
	const scrollBox = useRef<OverlayScrollbarsComponent>(null);

	const setTextInputRef = useCallback((element: HTMLPreElement) => {
		const osInstance = scrollBox.current?.osInstance();

		if (osInstance && element) {
			osInstance.scrollStop().scroll({ el: element, block: { y: 'center' } }, 200);
		}
	}, []);

	if (!lyrics) return null;

	const lines = lyrics.split('\n');
	let lineNumber = -1;

	return (
		<LyricsContextProvider>
			<Container>
				<LyricsContainer>
					<Separator />
					<StyledScrollBox ref={scrollBox}>
						{lines.map((line, index) => {
							lineNumber = incrementLineNumber(line, lineNumber);
							return (
								<Line
									key={index}
									ref={activeRowIndex === lineNumber ? setTextInputRef : null}
									highlighted={activeRowIndex === lineNumber}
								>
									<Markdown
										options={{
											overrides: {
												a: Comment,
												code: Indent,
											},
										}}
									>
										{line}
									</Markdown>
								</Line>
							);
						})}
					</StyledScrollBox>
					<Separator flipped={true} />
				</LyricsContainer>
				<CommentOverlay />
			</Container>
		</LyricsContextProvider>
	);
};
