import React, { useState, useEffect, useMemo, SetStateAction, Dispatch } from 'react';
import { Data, Playlists, Track } from '../types/types';
import { load as loadYaml } from 'js-yaml';

interface AppContext {
	tracks: Track[];
	isLoading: boolean;
	currentPlaylist: string;
	setCurrentPlaylist: Dispatch<SetStateAction<string>>;
	playlists: Playlists | null;
	// selectedTrack: Track | null;
	// setSelectedTrack: Dispatch<SetStateAction<Track | null>>;
}

export const AppContext = React.createContext({} as AppContext);

export const AppContextProvider: React.FC = ({ children }) => {
	const [data, setData] = useState<Data | null>(null);
	const [playlists, setPlaylists] = useState<Playlists | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true); // TODO: Use library to handle fetch and loading state
	const [currentPlaylist, setCurrentPlaylist] = useState<string>('virumaa');

	// const [selectedTrack, setSelectedTrack] = useState<Track | null>(null);

	async function fetchData() {
		return await fetch('/assets/data.yml')
			.then((res) => res.text())
			.then((text) => loadYaml(text));
	}

	async function fetchPlaylists() {
		return await fetch('/assets/playlists.yml')
			.then((res) => res.text())
			.then((text) => loadYaml(text));
	}

	useEffect(() => {
		setIsLoading(true);

		Promise.all([fetchData(), fetchPlaylists()])
			.then(([data, playlists]) => {
				setData(data);
				setPlaylists(playlists);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const tracks = useMemo(() => {
		const allTracks = data?.tracks;

		if (!playlists) return [];

		const playlistTracks = allTracks?.filter((track) => {
			if (playlists[currentPlaylist].tracks.includes(track.id)) {
				return track;
			}
		});

		return playlistTracks || [];
	}, [data, playlists, currentPlaylist]);

	return (
		<AppContext.Provider value={{ tracks, isLoading, currentPlaylist, setCurrentPlaylist, playlists }}>
			{children}
		</AppContext.Provider>
	);
};
