import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Header } from '../components/Header';
import { Layout } from '../components/Layout';
import { Playlist } from '../components/Playlist';
import { Separator } from '../components/Separator';
import { AppContext } from '../contexts/AppContext';
import { screenSize } from '../styles/ScreenSize';
import { ViewParams } from '../types/types';

const Container = styled.div`
	margin: 0 auto;
	width: 50%;
	flex: 1 1 0;

	/* Height needs fixed value or else bottom separator flickers */
	height: 0;

	${screenSize.sm} {
		width: 100%;
	}
`;

export const PlaylistView: React.FC = () => {
	const { playlistId } = useParams<ViewParams>();
	const { setCurrentPlaylist } = useContext(AppContext);

	useEffect(() => {
		if (playlistId) {
			setCurrentPlaylist(playlistId);
		}
	}, [playlistId, setCurrentPlaylist]);

	return (
		<Layout>
			{/* <Back to={'/'} /> */}
			<Header />
			<Container>
				<Separator />
				<Playlist />
				<Separator flipped={true} />
			</Container>
		</Layout>
	);
};
