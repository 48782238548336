import React from 'react';
import styled from 'styled-components';

interface SliderThumb {
	position: number;
}

const Svg = styled.svg`
	position: absolute;
	width: 24px;
	height: 24px;
	fill: none;
	stroke: var(--primary-color, #fff);
	stroke-width: 2px;
	pointer-events: none;
`;

export const SliderThumb: React.FC<SliderThumb> = ({ position }) => {
	return (
		<Svg style={{ left: position }} viewBox={'-60 -60 120 120'}>
			<polygon vectorEffect="non-scaling-stroke" points="30,-52 60,0 30,52 -30,52 -60,0 -30,-52" />
			<line vectorEffect="non-scaling-stroke" x1="-30" y1="-52" x2="30" y2="52" />
			<line vectorEffect="non-scaling-stroke" x1="30" y1="-52" x2="-30" y2="52" />
			<line vectorEffect="non-scaling-stroke" x1="-60" y1="0" x2="60" y2="0" />
		</Svg>
	);
};
