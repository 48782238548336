import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ViewParams } from '../types/types';
import { screenSize } from '../styles/ScreenSize';
import { AppContext } from '../contexts/AppContext';
import { Player } from '../components/Player';
import { Layout } from '../components/Layout';
import { Back } from '../components/Back';
import { Avatar } from '../components/Avatar';
import { TrackInfo } from '../components/TrackInfo';
import { findNextItemInArray, findPreviousItemInArray } from '../utils/navigateArray';

const TrackHeader = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;

	${screenSize.sm} {
		grid-template-columns: 110px 1fr;
	}
`;

const TrackAvatar = styled(Avatar)`
	margin-right: 24px;
	justify-self: right;

	${screenSize.sm} {
		margin-right: 16px;
	}
`;

const HeaderTrackInfo = styled(TrackInfo)`
	max-width: 300px;
`;

export const PlayerView: React.FC = () => {
	const { t } = useTranslation();
	const { tracks, isLoading, currentPlaylist, setCurrentPlaylist, playlists } = useContext(AppContext);
	const { playlistId, trackId } = useParams<ViewParams>();

	useEffect(() => {
		setCurrentPlaylist(playlistId);
	}, [playlistId, setCurrentPlaylist]);

	const tracklist = useMemo(() => playlists?.[currentPlaylist].tracks || [], [playlists, currentPlaylist]);
	const selectedTrack = useMemo(() => tracks.find((track) => track.id === trackId), [trackId, tracks]);
	const currentTrackIndex = tracklist.indexOf(trackId);

	const nextTrack = useMemo(() => {
		if (tracklist.length === 0) return;
		return findNextItemInArray(tracklist, currentTrackIndex);
	}, [currentTrackIndex, tracklist]);

	const prevTrack = useMemo(() => {
		if (tracklist.length === 0) return;
		return findPreviousItemInArray(tracklist, currentTrackIndex);
	}, [currentTrackIndex, tracklist]);

	if (!selectedTrack && !isLoading) {
		console.error(`Track not found: ${trackId}`);
		return null;
	}

	return (
		<Layout>
			<Back to={`/${currentPlaylist}`} />

			{selectedTrack && (
				<>
					<TrackHeader>
						<TrackAvatar src={selectedTrack.image} size={'large'} />
						<HeaderTrackInfo track={selectedTrack} />
					</TrackHeader>
					<Player
						audio={selectedTrack.src}
						subtitles={selectedTrack.captions}
						lyrics={t(`${trackId}.lyrics`)}
						nextTrackId={nextTrack}
						prevTrackId={prevTrack}
					/>
				</>
			)}
		</Layout>
	);
};
