import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Slider } from './Slider/Slider';
import { VideoCurrentTime, VideoDuration } from './VideoDuration';
import { ReactComponent as Play } from '../assets/icons/play.svg';
import { ReactComponent as Pause } from '../assets/icons/pause.svg';
import { ReactComponent as Skip } from '../assets/icons/skip.svg';
import { screenSize } from '../styles/ScreenSize';
import { Link } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	width: 100%;
`;

const ControlsRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: start;
	width: 100%;
`;

const PlayContainer = styled.div`
	display: flex;
	justify-self: center;
	align-items: center;
	grid-column: 2;
`;

const ControlButton = styled.button`
	display: flex;
	padding: 0;

	border: none;
	background-color: transparent;
	margin-left: auto;

	cursor: pointer;

	svg {
		width: 20px;
		height: 20px;
		stroke-width: 2px;
		stroke: var(--primary-color, #fff);
	}

	:not(:last-child) {
		margin-right: 24px;
	}

	${screenSize.sm} {
		:not(:last-child) {
			margin-right: 18px;
		}
	}
`;

const NavButton = styled(Link)`
	display: flex;
	padding: 0;

	border: none;
	background-color: transparent;
	margin-left: auto;

	cursor: pointer;

	svg {
		width: 20px;
		height: 20px;
		stroke-width: 2px;
		stroke: var(--primary-color, #fff);
	}

	:not(:last-child) {
		margin-right: 24px;
	}

	${screenSize.sm} {
		:not(:last-child) {
			margin-right: 18px;
		}
	}
`;

const SkipFlipped = styled(Skip)`
	transform: scaleX(-1);
`;

const CurrentTimeContainer = styled.div`
	justify-self: start;

	${screenSize.sm} {
		font-size: 0.8rem;
	}
`;

const DurationContainer = styled.div`
	justify-self: end;

	${screenSize.sm} {
		font-size: 0.8rem;
	}
`;

const ProgressContainer = styled.div`
	height: 32px;
`;

interface PlayerControls {
	progressValue: number;
	className?: string;
	video: HTMLVideoElement;
	nextTrackId?: string;
	prevTrackId?: string;
}

export const PlayerControls: FC<PlayerControls> = ({ progressValue, className, video, nextTrackId, prevTrackId }) => {
	const { t } = useTranslation();
	const { currentPlaylist } = useContext(AppContext);
	function toggleVideo() {
		if (video.paused) {
			video.play();
		} else {
			video.pause();
		}
	}

	function handleSeek(value: number) {
		video.currentTime = value * video.duration;
	}

	return (
		<Container className={className}>
			<ProgressContainer aria-hidden={true}>
				<Slider onChange={handleSeek} value={progressValue} />
			</ProgressContainer>
			<ControlsRow>
				<CurrentTimeContainer>
					<VideoCurrentTime video={video} />
				</CurrentTimeContainer>
				<PlayContainer>
					<NavButton to={`/${currentPlaylist}/${prevTrackId}`}>
						<SkipFlipped aria-label={t('ui.previous')} />
					</NavButton>
					<ControlButton onClick={toggleVideo}>
						{video.paused ? <Play aria-label={t('ui.play')} /> : <Pause aria-label={t('ui.pause')} />}
					</ControlButton>
					<NavButton to={`/${currentPlaylist}/${nextTrackId}`}>
						<Skip aria-label={t('ui.next')} />
					</NavButton>
				</PlayContainer>
				<DurationContainer>
					<VideoDuration video={video} />
				</DurationContainer>
			</ControlsRow>
		</Container>
	);
};
