import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { Link } from 'react-router-dom';
import { TrackInfo } from './TrackInfo';
import { screenSize } from '../styles/ScreenSize';
import { Avatar } from './Avatar';
import { ScrollBox } from '@erm-tasku/components';

const StyledScrollBox = styled(ScrollBox)`
	margin: -7px 0;
	width: 100%;
	height: calc(100% - 14px);
	overflow-y: auto;
`;

const List = styled.ol`
	display: flex;
	flex-direction: column;
	height: 100%;
	list-style: none;
	padding: 0;
`;

const ListItem = styled.li`
	display: flex;
	width: 100%;
	box-sizing: border-box;
	padding: 8px 16px;
	border-radius: 10px;
	align-items: center;

	a {
		display: block;
		height: 100%;
	}

	:hover {
		background-color: var(--active-highlight);
	}

	${screenSize.sm} {
		padding: 8px 12px 8px 8px;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 24px;
`;

const TrackDuration = styled.p`
	margin-left: auto;
`;

interface Button {
	selected: boolean;
}

const Button = styled.button<Button>`
	background-color: ${(p) => (p.selected ? 'lightblue' : 'transparent')};
`;

export const Playlist: FC = () => {
	const { currentPlaylist, tracks } = useContext(AppContext);

	return (
		<StyledScrollBox>
			<List>
				{tracks.map((track) => {
					return (
						<Link to={`/${currentPlaylist}/${track.id}`} key={track.id}>
							<ListItem>
								<Avatar src={track.image} size={'small'} />
								<Column>
									<TrackInfo track={track} />
								</Column>
								<TrackDuration>{track.duration}</TrackDuration>
							</ListItem>
						</Link>
					);
				})}
			</List>
		</StyledScrollBox>
	);
};
