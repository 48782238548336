import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { Avatar } from './Avatar';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Title = styled.h1`
	margin: 8px 0 16px 0;
`;

export const Header: FC = () => {
	const { t } = useTranslation();
	const { currentPlaylist, playlists } = useContext(AppContext);

	if (!playlists) return null;

	const playlistImg = playlists[currentPlaylist].img;

	return (
		<Container>
			<Avatar src={playlistImg} size={'large'} />
			<Title>{t(`locations.${currentPlaylist}`)}</Title>
		</Container>
	);
};
