import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { screenSize } from '../styles/ScreenSize';
import { ReactComponent as Chevron } from '../assets/icons/chevron.svg';
import { useTranslation } from 'react-i18next';

const BackLink = styled(Link)`
	position: absolute;
	display: flex;
	align-items: center;

	p {
		margin: 0 0 0 4px;
		text-transform: lowercase;
	}

	${screenSize.sm} {
		position: static;
		margin-bottom: 12px;
		font-size: var(--tag-text);
	}
`;

const BackIcon = styled(Chevron)`
	width: 10px;
	height: 12px;
	stroke-width: 2px;
	stroke: var(--primary-color, #fff);
`;

interface BackProps {
	to: string;
}

export const Back: FC<BackProps> = ({ to }) => {
	const { t } = useTranslation();
	return (
		<BackLink to={to}>
			<BackIcon aria-hidden={true} />
			<p>{t('ui.back')}</p>
		</BackLink>
	);
};
