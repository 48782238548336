import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PlayerView } from './views/PlayerView';
import { GlobalStyles } from './styles/GlobalStyles';
import './i18n';
import { PlaylistView } from './views/PlaylistView';
import { AppContextProvider } from './contexts/AppContext';
import ScrollToTop from './components/ScrollToTop';
import { Playlists } from './views/Playlists';

function App() {
	return (
		<Router>
			<ScrollToTop />
			<GlobalStyles />
			<AppContextProvider>
				<Switch>
					<Route path={'/:playlistId/:trackId'} component={PlayerView} />
					<Route path={'/:playlistId'} exact={true} component={PlaylistView} />
					<Route path={'/'} component={Playlists} />
				</Switch>
			</AppContextProvider>
		</Router>
	);
}

export default App;
