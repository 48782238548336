import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Track } from '../types/types';

const SmallText = styled.p`
	margin: 0;
	font-size: var(--tag-text);

	& + & {
		margin-top: 2px;
	}
`;

const Title = styled.p`
	margin: 4px 0 0 0;
	font-weight: bolder;

	&& {
		font-size: var(--body-text);
	}
`;

interface TrackInfo {
	track: Track;
	className?: string;
}

export const TrackInfo: FC<TrackInfo> = ({ track, className }) => {
	const { t } = useTranslation();

	return (
		<div className={className}>
			<SmallText>{t(`${track.id}.location`)}</SmallText>
			<SmallText>{t(`${track.id}.performer`)}</SmallText>
			<Title>{t(`${track.id}.title`)}</Title>
		</div>
	);
};
