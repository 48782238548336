export function formatDuration(time: number) {
	if (isNaN(time)) return '-:--';

	const minutes = Math.floor(time / 60);
	const seconds = prependZero(Math.floor(time % 60));

	return `${minutes}:${seconds}`;
}

function prependZero(number: number) {
	return ('0' + number).slice(-2);
}
