import styled from 'styled-components';
import ZigZag from '../assets/icons/zig.svg';

interface Props {
	flipped?: boolean;
}

export const Separator = styled.div<Props>`
	position: relative;
	z-index: 2;
	background: url(${ZigZag}) round;
	height: 11px;
	min-height: 11px;
	width: 100%;
	transform: scaleY(${(p) => (p.flipped ? -1 : 1)});
`;
