import React, { useEffect } from 'react';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { formatDuration } from '../utils/formatTime';

interface VideoDuration {
	video: HTMLVideoElement;
}

export const VideoCurrentTime: React.FC<VideoDuration> = ({ video }) => {
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		video.addEventListener('durationchange', forceUpdate);
		return () => video.removeEventListener('durationchange', forceUpdate);
	}, [video, forceUpdate]);

	return <>{formatDuration(video.currentTime)}</>;
};

export const VideoDuration: React.FC<VideoDuration> = ({ video }) => {
	const forceUpdate = useForceUpdate();
	useEffect(() => {
		video.addEventListener('durationchange', forceUpdate);
		return () => video.removeEventListener('durationchange', forceUpdate);
	}, [video, forceUpdate]);

	return <>{formatDuration(video.duration)}</>;
};
