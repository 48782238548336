import { CloseIcon } from '@erm-tasku/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLyricsContext } from '../../contexts/LyricsContext';
import { screenSize } from '../../styles/ScreenSize';

const Wrapper = styled.div`
	display: flex;
	width: calc(50% - 32px);
	margin-left: 32px;
	align-items: flex-start;
	justify-content: space-between;
	padding: 16px;
	background: rgba(0, 42, 62, 0.8);
	color: white;
	position: relative;

	p + p {
		margin: 8px 0 0 0;
	}

	${screenSize.sm} {
		width: calc(100% + 2 * 16px);
		margin: 0 -16px -16px -16px;
	}
`;

const Content = styled.div`
	margin-right: 36px;
`;

const Title = styled.p`
	font-weight: 600;
	margin: 0 0 4px 0;
`;

const CloseButton = styled.button`
	position: absolute;
	top: 8px;
	right: 8px;
	padding: 0;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;

	svg {
		width: 20px;
		height: 20px;
	}
`;

export const CommentOverlay: React.FC = () => {
	const { currentComment, setCurrentComment } = useLyricsContext();
	const { t } = useTranslation();

	if (!currentComment) return null;

	return (
		<Wrapper>
			<Content>
				<Title>{currentComment.key}</Title>
				<p>{currentComment.explanation}</p>
			</Content>
			<CloseButton onClick={() => setCurrentComment()}>
				<CloseIcon aria-label={t('ui.close')} />
			</CloseButton>
		</Wrapper>
	);
};
