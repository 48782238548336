import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from '../components/Layout';
import { AppContext } from '../contexts/AppContext';

const StyledLink = styled(Link)`
	display: block;
	margin: 16px 0;
`;

export const Playlists: React.FC = () => {
	const { playlists } = useContext(AppContext);

	return (
		<Layout>
			{playlists
				? Object.keys(playlists).map((playlist) => {
						return (
							<StyledLink key={playlist} to={playlist}>
								{playlist}
							</StyledLink>
						);
				  })
				: ''}
		</Layout>
	);
};
